export const environment = {
    api: 'https://integration-web-api.guideline.ai',
    loginURL: 'https://integration-web-login.guideline.ai',
    defaultRedirectEnv:'https://integration-web.guideline.ai',
    planningPlatformURL: 'https://intelligence.guideline.ai/',
    cognitoIdentityPoolId: 'us-east-1:68ec82d7-59b1-40ea-883a-84808c4cd1d3',
    cognitoRegion: 'us-east-1',
    cognitoUserPoolId: 'us-east-1_zgDqorVCc',
    cognitoUserPoolWebClientId: '42pkodktneahj2fbiqufkbmgrh',
    cognitoOauthDomain: 'guideline-customers-prod.auth.us-east-1.amazoncognito.com',
    loggerStreamName:'Stargate.UI',
    loggerRegion:'us-east-1',
    loggerGroupName:'/log/Stargate/UI'
};