const UserTokenManagementService = class {
    _authTokenKey;
    constructor(authTokenKey) {
        this._authTokenKey = authTokenKey ||  "authToken";
    }

    addUserIDToken(token) {
        localStorage.setItem(this._authTokenKey, token);
    }
    getUserIDToken() {
       return localStorage.getItem(this._authTokenKey);
    }
    removeUserIDToken() {
        localStorage.removeItem(this._authTokenKey);
    }
};

export default UserTokenManagementService;