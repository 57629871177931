const forgotPasswordMessages = {
    CODE_SENT_ERROR_MESSAGE: "There was an error trying to send the reset code.",
    PASSWORD_RESET_ERROR_MESSAGE: "There was an error trying to reset your password. Please try again.",
    PASSWORD_VALIDATION_ERROR_MESSAGE: "Password is required and should be at least 8 symbols long, contain number, uppercase values and at least one symbol.",
    ALL_FIELDS_REQUIRED_ERROR_MESSAGE: "All fields are required.",
    VERIFICATION_CODE_BUTTON_TEXT: "Send verification code",
    EMAIL_VALIDATION_ERROR_MESSAGE: "Email is not in the correct format.",
    EMAIL_PLACEHOLDER: "Email",
    VERIFICATION_CODE_PLACEHOLDER: "Verification code",
    PASSWORD_PLACEHOLDER: "New password",
    BACK_TO_SIGN_IN_TEXT: "Back to sign in",
    PASSWORD_RESET_SUCCESS_MESSAGE: "Password has been reset. Please log in with your new password."
};

export default forgotPasswordMessages;