const template = `
<style>
h4 {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 14px;
}
#login-form-wrapper {
    font-family: 'Roboto', sans-serif;
    width: 350px;    
}

.content-wrapper {
   padding: 35px;
   background: #FFFFFF;
   min-height: 300px;
   box-sizing: border-box;
   position: relative;
   padding-bottom: 100px;
}

.login-form * {
    box-sizing: border-box;
}

h2.header {
    font-size: 24px;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    text-align: left;
    padding-top:20px;
    padding-left:10px;
    margin-bottom:0;

}

.login-form input {
    margin-bottom: 15px;
}

.login-form input[type=text], .login-form input[type=email], .login-form input[type=password] {
    display: block;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #BDBDBD;
    background: #EEEEEE;
    height:24px;
    padding:1px 30px 1px 10px;
}

.input-wrapper input:invalid {
    border: 1px solid red;
}

.input-wrapper input:invalid + button {
   background: red;
}

.login-form input:hover, .login-form input:active, .login-form input:focus {
    border: 1px solid #0161fe;
    outline: none;
}

.login-form label, #alternative-login-message {
    color: #777;
    font-size: 0.8em;
}

#loading {
    width: 60px;
    margin: 60px auto;
}

.login-form button[type=submit], #logout-user, #login-with-sso, #email-submit, #show-login-form {
    display: block;
    margin: 5px auto 0;
    width: 100%;
    height: 40px;
    border: none;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    cursor: pointer;
    background: #0161fe;
    position: relative;
 }
 
 #sign-in-icon {
    position: absolute;
    right: 20px;
    top: 10px;
 }
.loader-container {
    z-index: 5000;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.guideline-loader {
    z-index: 1000;
    display: flex;
    position: relative;
    flex-direction: row;
  }
  
  .guideline-loader path {
    animation: guideline-loader .75s cubic-bezier(1, .5, .5, 1) infinite;
  }
  
  .guideline-loader path:nth-child(1) {
    animation-delay: -0.44s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(2) {
    animation-delay: -0.32s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(3) {
    animation-delay: -0.22s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(4) {
    animation-delay: -0.12s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(5) {
    animation-delay: -0.02s;
    fill: #061856;
  }
  
  .guideline-loader path {
    animation: guideline-loader .75s cubic-bezier(1, .5, .5, 1) infinite;
  }
  
  .guideline-loader path:nth-child(1) {
    animation-delay: -0.44s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(2) {
    animation-delay: -0.32s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(3) {
    animation-delay: -0.22s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(4) {
    animation-delay: -0.12s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(5) {
    animation-delay: -0.02s;
    fill: #061856;
  }
  
  @keyframes guideline-loader {
    0% {
      opacity: 1;
      box-shadow: none;
      opacity: 1;
      box-shadow: none;
    }
    90%, 100% {
      opacity: .25;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 1);
    }
  }

  
  .gl-disabled-overlay {
    display: flex;
    position: absolute;
    background-color: rgba(var(--surface-card-rgb), 0.2);
    align-items: center;
    flex-direction: column;
    z-index: 3;
    height: 100%;
    width: 100%;
    justify-content: center;
    transition: opacity 0.5s ease;
    opacity: 1;
  }
  #organization-select {
    width: 100%;
  }
   
  .gl-disabled-overlay.hide {
    opacity: 0;
    pointer-events: none;
  }

.user-table {
    border-collapse: collapse;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
}

.user-table th,
.user-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.user-table th {
    background-color: #f2f2f2;
}

.user-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.user-table tr:hover {
    background-color: #ddd;
}

 .forgot-password {
  text-align: center;
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
}

#support-link {
    text-align: center;
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    font-family: Roboto, sans-serif;    
    padding: 0 35px 25px 35px;
}

#support-link span, #sendVerificationCode {
    color: #0161fe;
}

#loader{
    display: none;
}

#alternative-login-message {    
    text-align: center;
}
.input-wrapper{
    position: relative;
}
.input-wrapper img {
    position: absolute;
    right: 5px;
    top: 3px;
}
#eye-icon {
    top:3px;
    right: 7px
}
#logoImage, #logoImage2 {
    width: 220px;
    margin: 20px auto;
    display: block;
}
#user-icon {
    padding-top: 2px;
}
#stay-logged-in + label {
    margin-top: -5px;
}
#stay-logged-in-container {
    margin-bottom: 15px;
}
#stay-logged-in-container #stay-logged-in {
    vertical-align: middle;
    margin: 0 5px 0 0;
}
#email-submit:disabled {
    background: gray;
    cursor: auto;
}
.copyright {
    padding: 10px 0;
    text-align: center;
    font-size: 10px;
    color: #757575;
}

 .login-form input[type="password"]::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    width: 0;
}

 .login-form input[type="password"]:focus::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    width: 0;
}
 .login-form input[type="password"]::-webkit-textfield-decoration-container {
    display: none;
}

 .login-form input[type="password"]:focus::-webkit-textfield-decoration-container {
    display: none;
}

input::-ms-reveal { display: none; }

{
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
        
        body, html {
            height: 100%;
            font-family: Arial, sans-serif;
        }
        
        .container {
            position: relative;
            height: 100vh;
            width: 100%;
        }
        
        .login-section {
            position: absolute;
            top: 0;
            left: 0;
            width: 350px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
        }
        
        .login-box {
            width: 100%;
            padding: 20px;
        }       
        

        .image-section {
           flex: 1;
            height: 100vh;
            position: absolute;
            top: 0;
            right: 0;
            width: calc(100% - 350px);
            background-image: url("assets/sign-in-mediatools.png");
            background-size: cover;
            background-position: center;
            z-index: -1;
        }
            
        .image-section::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(107, 134, 179, 0.5);
            z-index: 1;
        }
        
        #login-form-wrapper {
            width: 100%;
            max-width: 350px;
        }
        
        .content-wrapper {
            padding: 15px;
        }
        
        #logoImage {
            display: block;
            margin: 0 auto 20px;
            max-width: 200px;
        }

        .login-footer {
            position: absolute;
            bottom: 20px;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 14px;
            color: #666;
            padding: 0 15px;
        }
        
        .loader-container {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.8);
            z-index: 1000;
            justify-content: center;
            align-items: center;
        }
        
        .gl-disabled-overlay {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
        
        .guideline-loader svg {
            animation: pulse 1.5s infinite;
        }
        
        @keyframes pulse {
            0% { opacity: 0.6; }
            50% { opacity: 1; }
            100% { opacity: 0.6; }
        }

</style>
<div class="container">
        <div class="login-section">
            <div class="login-box">
                <div id="login-form-wrapper">
                    <img id="logoImage" alt="logo"/>
                    <div class="content-wrapper">
                        <form class="login-form" id="login-form-federated" style="display: block">
                            <h4>Enter your email</h4>
                           <div class="input-wrapper">
                            <input type="email" id="email" name="email" />
                            <img id="user-icon" alt="user-icon"/>
                            </div>
                            <button id="email-submit" type="submit"> Submit </button>
                            <p id="email-error-message" style="color: red; display: none"></p>
                        </form>
                        <ce-guideline-forgot-password style="display:none" id="forgotPasswordForm"></ce-guideline-forgot-password>
                        <ce-guideline-reset-password style="display:none" id="resetPasswordForm"></ce-guideline-reset-password>
                        <form class="login-form" id="login-form" style="display: none">
                            <h4>Sign in to continue</h4>
                            <p id="error-message" style="color: red; display: none"></p>
                           <div class="input-wrapper">
                            <input type="text" id="username" name="username">
                            <img id="user-icon2" alt="user-icon"/>
                            </div>
                            <div class="input-wrapper">
                            <input type="password" inputmode="none" id="password" name="password">
                                <img id="eye-icon" alt="eye-icon"/>
                            </div>

                            <button id="login-form-submit" type="submit">Sign In <img id="sign-in-icon" alt="sign-in-icon" /></button>
                        <p class="forgot-password">
                                Forgot Password?
                                <a id="sendVerificationCode" href="#">Reset your password here</a>
                          </p>
                        </form>
                        <form class="login-form" id="login-form-organization-select" style="display: none">
                            <h4>Select organization</h4>
                           <div class="input-wrapper">
                            <select name="orgSelect" id="organization-select"></select>
                            <small id="no-organization" style="color: red;display:none">There are no active organizations associated with your account. Please reach out to your Account Manager</small>
                            <button id="org-submit" type="submit"> Submit </button>
                            </div>
                        </form>
                          <p id="support-link">
                                Having issues or don't have an account?
                                Contact client support at <span>help@guideline.ai</span>
                            </p>
                            <div id="loader" class="loader-container">
                                <div class="gl-disabled-overlay">
                                    <div class="guideline-loader">
                                        <svg width="70" height="43.64" viewBox="0 0 365 227" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M60.339 139.811C57.6407 139.089 54.8204 138.706 51.9347 138.699H51.8497C38.6563 138.699 26.8243 146.549 21.6603 158.72L9.94151 186.334L1.17393 206.895C-1.94717 214.117 1.37315 222.479 8.55088 225.606C15.7579 228.734 24.1007 225.407 27.2218 218.213L47.7638 169.868C47.8692 169.616 47.9864 169.386 48.1144 169.177L60.339 139.811Z"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M96.457 226.103L109.452 194.884C107.653 197.926 104.637 198.277 103.349 198.277C101.901 198.277 98.3828 197.851 96.7373 193.869L82.0967 158.862C78.1045 149.325 70.002 142.396 60.3389 139.811L48.1143 169.178C49.125 167.525 50.7666 167.166 51.8496 167.166C53.2119 167.166 54.999 167.649 55.9355 169.896L70.5762 204.903C75.3096 216.204 84.8906 223.907 96.457 226.103Z"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M189.676 76.2222C187.308 75.7164 184.862 75.4531 182.369 75.4531C168.296 75.4531 155.726 83.8424 150.306 96.867L109.959 193.869C109.84 194.152 109.712 194.416 109.576 194.664L96.4873 226.108C98.7077 226.527 101.001 226.744 103.348 226.744H103.376C117.989 226.744 130.53 218.355 136.148 204.846L142.247 190.183L142.24 190.18L153.451 163.246L176.496 107.844C176.563 107.682 176.634 107.525 176.708 107.376L189.676 76.2222Z"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M257.82 200.718L256.397 197.623L214.433 96.8672C209.98 86.168 200.703 78.5967 189.731 76.2344L176.969 106.896C178.482 104.334 180.942 103.92 182.37 103.92C183.959 103.92 186.824 104.432 188.243 107.845L230.208 208.601C233.539 216.602 240.026 222.577 247.878 225.326C247.873 225.324 247.87 225.316 247.865 225.314L250.93 217.771L257.82 200.718Z"/>
                                            <path d="M247.865 225.315C248.196 225.432 248.471 225.522 248.678 225.584C248.678 225.584 249.194 225.749 249.71 225.894C252.292 226.612 254.998 226.998 257.766 226.998H257.794C269.912 226.97 280.721 219.719 285.341 208.486L363.15 19.6553C366.159 12.4037 362.696 4.06889 355.46 1.08823C348.223 -1.92694 339.913 1.54359 336.932 8.79518C310.995 71.7273 285.066 134.659 259.129 197.592L247.865 225.315Z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ce-guideline-modal id="custom-modal"></ce-guideline-modal>
                    </div>
                </div>
            
            <div class="login-footer">
                    <p class="copyright">COPYRIGHT © 2025 Guideline</p>
                </div>
            </div>
        </div>        
        <div class="image-section"></div>
    </div>
`

export default template;