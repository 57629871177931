import resetPasswordComponentTemplate from "./resetPasswordComponentTemplate.js";
import {eyeIcon} from "../LoginComponent/LoginComponentIcons.js";
import forgotPasswordMessages from "../../constants/forgotPasswordMessages.js";


export default class CEGuidelineCognitoResetPassword extends HTMLElement {
  constructor() {
    super();
    const shadow = this.attachShadow({ mode: "open" });
    const template = document.createElement("template");
    template.innerHTML = resetPasswordComponentTemplate;
    shadow.appendChild(template.content.cloneNode(true));
    this.shadowRoot.querySelector("#new-password-form-submit").addEventListener("click", this.onResetPasswordSent.bind(this));
    this.shadowRoot.querySelector("#new-password-eye-icon").addEventListener("click", this.showHidePassword.bind(this));
    this.shadowRoot.querySelector("#new-reset-password").placeholder = forgotPasswordMessages.PASSWORD_PLACEHOLDER;
    this.shadowRoot.querySelector("#backtologin").innerText = forgotPasswordMessages.BACK_TO_SIGN_IN_TEXT;
    this.shadowRoot.querySelector("#backtologin").addEventListener("click", this.onBackToSignIn.bind(this));
    this.addIcons();
  }

  addIcons() {
    const eyeImages = this.shadowRoot.querySelector("#new-password-eye-icon");   
    eyeImages.setAttribute("src", eyeIcon);
  }

  
  showPasswordResetErrorMessage() {
    const errorMessage = this.shadowRoot.querySelector(
      "#new-password-error-message"
    );
    errorMessage.style.display = "block";
    const message = forgotPasswordMessages.PASSWORD_RESET_ERROR_MESSAGE;
    errorMessage.innerText = message;
  }


  onBackToSignIn() {   
    setTimeout(function(){
      window.location.reload();
    });
  }

  validatePassword(event) {
    const value = event.target.value;
    if (!/[!@#$%^&*_{}()]/.test(value)) {
      const errorMessage = this.shadowRoot.querySelector("#forgot-password-error-message");
      errorMessage.style.display = "block";
      const message = forgotPasswordMessages.PASSWORD_VALIDATION_ERROR_MESSAGE;
      errorMessage.innerText = message;
    } else {
      const errorMessage = this.shadowRoot.querySelector("#forgot-password-error-message");
      errorMessage.style.display = "none";
    }
  }


  getFormValues() {
    const newPassword = this.shadowRoot.querySelector("#new-reset-password").value;
    return {
        newPassword
    };
  }

  validateForm() {
      const { newPassword } = this.getFormValues();
      const passwordRegex =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;
      const displayBlock = "block";
      const displayNone = "none";
      const bullet = "&#8226; ";
      let valid = true;

      const errorMessage = this.shadowRoot.querySelector("#new-password-error-message");
      errorMessage.innerHTML = "";
      errorMessage.style.display = displayNone;      

      if (!passwordRegex.test(newPassword)) {
          errorMessage.style.display = displayBlock;
          errorMessage.innerHTML += bullet + forgotPasswordMessages.PASSWORD_VALIDATION_ERROR_MESSAGE;
          valid = false;
      }

      return valid;
  }

  async onResetPasswordSent(e) {
      e.preventDefault();

      if (!this.validateForm()) {
          return;
      }

      const { newPassword } = this.getFormValues();
      const displayBlock = "block";

      this._cognito.changePassword(this.user, newPassword).then((data) => {
              this.shadowRoot.querySelector("#new-password-sucess-message").style.display = displayBlock;
              this.shadowRoot.querySelector("#new-password-sucess-message").innerText = forgotPasswordMessages.PASSWORD_RESET_SUCCESS_MESSAGE;
              setTimeout(() => {
                location.reload(true);
              }, 5000);
          }).catch((err) => {
              this.showPasswordResetErrorMessage();
          });
  }

  showHidePassword() {
    const passwordInput = this.shadowRoot.querySelector("#new-reset-password");
    passwordInput.type = passwordInput.type === "password" ? "text" : "password";
  }

  set user(value) {
    this._user = value;
  }

  get user() {
    return this._user;
  }

  get cognito() {
    return this._cognito;
  }

  set cognito(value) {
    this._cognito = value;
  }
}
