import {Auth} from 'aws-amplify';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

import UserTokenManagementService from "./userTokenManagementService";
import logger from "./loggerService";

import defaultConfigForCognito from "../constants/cognitoConfig";

import { Hub } from 'aws-amplify';

const listener = async (data) => {
    switch (data?.payload?.event) {
        case 'tokenRefresh':
            if (window.localStorage.getItem('rememberMe') == 'false') {
                await Auth.signOut();
            }
            break;
        default:
            break;
    }
};

Hub.listen('auth', listener);

class CognitoService {
    _configuration;

    constructor(externalConfig) {
        const currentHost = window.location.origin;

        this.tokenManagement = new UserTokenManagementService();

        const defaultConfig = {
            identityPoolId: defaultConfigForCognito.identityPoolId,
            region: defaultConfigForCognito.region,
            userPoolId: defaultConfigForCognito.userPoolId,
            userPoolWebClientId: defaultConfigForCognito.userPoolWebClientId,
            oauth: {
                domain: defaultConfigForCognito.oauth.domain,
                redirectSignIn: `${currentHost}/access-token`,
                redirectSignOut: `${currentHost}/`,
                scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
                responseType: 'code'
            }
        }

        this._configuration = externalConfig || defaultConfig;

        Auth.configure(this._configuration);
    }

    async signInUser(userName, password, rememberMe) {
        const user = await Auth.signIn(userName, password);
        if (user && (user.challengeName !== 'NEW_PASSWORD_REQUIRED')) {
            logger.info('user successfully signed in', userName);
        }
        window.localStorage.setItem('rememberMe', rememberMe);
        return user;
    }

    async federatedSignIn(domain) {
        return await Auth.federatedSignIn({ provider: domain, customState: new Date().getTime().toString()});
    }

    async signInUserWithSSO() {
        try {
            return Auth.federatedSignIn({provider: 'Auth0'});
        } catch (e) {
            console.log(e);
        }
    }

    async signOutUser() {
        try {
            const signOutUser = await Auth.signOut({global:true});
            localStorage.clear();

            // HACK to enforce signeOut from the federated provider we have to call this endpoint
            // https://github.com/aws-amplify/amplify-js/issues/1583#issuecomment-490315706

             const federatedSigneOutUrl = `https://${this._configuration.oauth.domain}/logout?client_id=${this._configuration.userPoolWebClientId}&logout_uri=${this._configuration.oauth.redirectSignOut}`
             window.location.replace(federatedSigneOutUrl)

            localStorage.clear();
            return signOutUser;
        } catch (e) {
            console.log(e);
        }
    }

    async checkAuthentication() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.getJwtToken();
            this.tokenManagement.addUserIDToken(token);
            return user;
        } catch (e) {
            console.log(e);
        }
    }

    async getSession() {
        try {
            return await Auth.currentSession();
        } catch (e) {
            console.log(e);
        }
    }

    async dispatchTokenRefresh(){
        Hub.dispatch('auth', { event: 'tokenRefresh' });
    }

    parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    async authorizeByTokens(idToken, accessToken, refreshToken) {
        // create a CognitoAccessToken using the response accessToken
        const IdToken = new AmazonCognitoIdentity.CognitoIdToken({ IdToken: idToken });
        const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({ AccessToken: accessToken });
        const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({RefreshToken: refreshToken });

        const sessionData = {
            IdToken: IdToken,
            AccessToken: AccessToken,
            RefreshToken: RefreshToken,
        };

        const session = new AmazonCognitoIdentity.CognitoUserSession(sessionData);

        let poolData = {
            UserPoolId: this._configuration.userPoolId,
            ClientId: this._configuration.userPoolWebClientId,
        };
        
        // pass the poolData object to CognitoUserPool 
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        
        // create an object containing the username and user pool. 
        // You can get the username from CognitoAccessToken object 
        // we created above.
        let userData = {
            Username: AccessToken.payload.username, 
            Pool: userPool
        };
        
        // create a cognito user using the userData object 
        let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        
        // set the cognito user session w/ the CognitoUserSession
        cognitoUser.setSignInUserSession(session);
        
        // get the Amplify authenticated user      
        return Auth.currentAuthenticatedUser({
            bypassCache: true // If set to true, this call will send a request to Cognito to get the latest user data
        });
    }

    saveRememberMeToLs(rememberMe) {
        window.localStorage.setItem('rememberMe', rememberMe);
    }

    async initiatePasswordReset(username) {
        return Auth.forgotPassword(username);
    }

    async completePasswordReset(username, code, newPassword) {
        return Auth.forgotPasswordSubmit(username, code, newPassword);
    }

    async changePassword(username, newPassword) {
        return Auth.completeNewPassword(username, newPassword);       
    }

}

export default CognitoService;