import forgotPasswordComponentTemplate from "./forgotPasswordComponentTemplate.js";
import { userIcon, eyeIcon, buildCircle } from "../LoginComponent/LoginComponentIcons.js";
import forgotPasswordMessages from "../../constants/forgotPasswordMessages.js";


export default class CEGuidelineCognitoForgotPassword extends HTMLElement {
  constructor() {
    super();
    const shadow = this.attachShadow({ mode: "open" });
    const template = document.createElement("template");
    template.innerHTML = forgotPasswordComponentTemplate;
    shadow.appendChild(template.content.cloneNode(true));
    this.shadowRoot.querySelector("#submit-verfication-code").innerText = forgotPasswordMessages.VERIFICATION_CODE_BUTTON_TEXT;
    this.shadowRoot.querySelector("#submit-verfication-code").addEventListener("click", this.onVerificationCodeSent.bind(this));
    this.shadowRoot.querySelector("#forgotpassowrd-form-submit").addEventListener("click", this.onResetPasswordSent.bind(this));
    this.shadowRoot.querySelector("#forgot-password-eye-icon").addEventListener("click", this.showHidePassword.bind(this));
    this.shadowRoot.querySelector("#forgotpasswordemail").addEventListener("change", this.onEmailChange.bind(this));
    this.shadowRoot.querySelector("#verification-code").placeholder = forgotPasswordMessages.VERIFICATION_CODE_PLACEHOLDER;
    this.shadowRoot.querySelector("#new-password").placeholder = forgotPasswordMessages.PASSWORD_PLACEHOLDER;
    // this.shadowRoot.querySelector("#new-password").addEventListener("change", this.validatePassword.bind(this));
    this.shadowRoot.querySelector("#forgot-password-username").placeholder = forgotPasswordMessages.EMAIL_PLACEHOLDER;
    this.shadowRoot.querySelector("#backtologin").innerText = forgotPasswordMessages.BACK_TO_SIGN_IN_TEXT;
    this.shadowRoot.querySelector("#backtologin").addEventListener("click", this.onBackToSignIn.bind(this));
    this.addIcons();
  }

  addIcons() {
    const userImages = this.shadowRoot.querySelectorAll(".user-icon3");
    const eyeImages = this.shadowRoot.querySelectorAll(".eye-icon");
    const buildImages = this.shadowRoot.querySelectorAll(".buildIcon");

    userImages.forEach((image) => {
      image.setAttribute("src", userIcon);
    });

    eyeImages.forEach((image) => {
      image.setAttribute("src", eyeIcon);
    });

    buildImages.forEach((image) => {
      image.setAttribute("src", buildCircle);
    });
  }

  showCodeSentErrorMessage() {
    const errorMessage = this.shadowRoot.querySelector(
      "#forgot-password-code-error-message"
    );
    errorMessage.style.display = "block";
    const message = forgotPasswordMessages.CODE_SENT_ERROR_MESSAGE;
    errorMessage.innerText = message;
  }

  showPasswordResetErrorMessage() {
    const errorMessage = this.shadowRoot.querySelector(
      "#forgot-password-error-message"
    );
    errorMessage.style.display = "block";
    const message = forgotPasswordMessages.PASSWORD_RESET_ERROR_MESSAGE;
    errorMessage.innerText = message;
  }

  showResetPassword() {
    this.shadowRoot.querySelector("#reset-password-form").style.display = "block";
    this.shadowRoot.querySelector("#verfication-code-form").style.display = "none";
  }

  onBackToSignIn() {   
    setTimeout(function(){
      window.location.reload();
    });
  }

  async onVerificationCodeSent(e) {
    e.preventDefault();
    const email = this.shadowRoot.querySelector("#forgotpasswordemail").value;   

    if (!email || !this.validateEmail(email)) {
      return;
    }

    this._cognito.initiatePasswordReset(email).then((data) => {
        console.log(data);
        this.showResetPassword();
      }).catch((err) => {
        console.log(err);
        this.showCodeSentErrorMessage();
      });
  }

  validatePassword(event) {
    const value = event.target.value;
    if (!/[!@#$%^&*_{}()]/.test(value)) {
      const errorMessage = this.shadowRoot.querySelector("#forgot-password-error-message");
      errorMessage.style.display = "block";
      const message = forgotPasswordMessages.PASSWORD_VALIDATION_ERROR_MESSAGE;
      errorMessage.innerText = message;
    } else {
      const errorMessage = this.shadowRoot.querySelector("#forgot-password-error-message");
      errorMessage.style.display = "none";
    }
  }

  validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      const errorMessage = this.shadowRoot.querySelector("#forgot-password-code-error-message");
      errorMessage.style.display = "block";
      const message = forgotPasswordMessages.EMAIL_VALIDATION_ERROR_MESSAGE;
      errorMessage.innerText = message;
      return false
    } else {
      const errorMessage = this.shadowRoot.querySelector("#forgot-password-code-error-message");
      errorMessage.style.display = "none";
      return true;
    }    
  }

  onEmailChange(event) {
    const value = event.target.value;
    this.validateEmail(value);
  }

  getFormValues() {
    const email = this.shadowRoot.querySelector("#forgot-password-username").value;
    const verificationCode = this.shadowRoot.querySelector("#verification-code").value;
    const newPassword = this.shadowRoot.querySelector("#new-password").value;

    return {
        email,
        verificationCode,
        newPassword
    };
  }

  validateForm() {
      const { email, verificationCode, newPassword } = this.getFormValues();
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const passwordRegex =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;
      const newLine = "<br>";
      const displayBlock = "block";
      const displayNone = "none";
      const bullet = "&#8226; ";
      let valid = true;

      const errorMessage = this.shadowRoot.querySelector("#forgot-password-error-message");
      errorMessage.innerHTML = "";
      errorMessage.style.display = displayNone;

      if (!email || !verificationCode || !newPassword) {
          errorMessage.style.display = displayBlock;
          errorMessage.innerHTML += bullet + forgotPasswordMessages.ALL_FIELDS_REQUIRED_ERROR_MESSAGE + newLine;         
          valid = false;         
      }

      if (!emailRegex.test(email)) {
          errorMessage.style.display = displayBlock;
          errorMessage.innerHTML += bullet + forgotPasswordMessages.EMAIL_VALIDATION_ERROR_MESSAGE + newLine;
          valid = false;
      }

      if (!passwordRegex.test(newPassword)) {
          errorMessage.style.display = displayBlock;
          errorMessage.innerHTML += bullet + forgotPasswordMessages.PASSWORD_VALIDATION_ERROR_MESSAGE;
          valid = false;
      }

      return valid;
  }

  async onResetPasswordSent(e) {
      e.preventDefault();

      if (!this.validateForm()) {
          return;
      }

      const { email, verificationCode, newPassword } = this.getFormValues();
      const displayBlock = "block";

      this._cognito.completePasswordReset(email, verificationCode, newPassword).then((data) => {
              console.log(data);
              this.shadowRoot.querySelector("#forgot-password-sucess-message").style.display = displayBlock;
              this.shadowRoot.querySelector("#forgot-password-sucess-message").innerText = forgotPasswordMessages.PASSWORD_RESET_SUCCESS_MESSAGE;
              setTimeout(() => {
                location.reload(true);
              }, 5000);
          }).catch((err) => {
              console.log(err);
              this.showPasswordResetErrorMessage();
          });
  }

  showHidePassword() {
    const passwordInput = this.shadowRoot.querySelector("#new-password");
    passwordInput.type = passwordInput.type === "password" ? "text" : "password";
  }

  get cognito() {
    return this._cognito;
  }

  set cognito(value) {
    this._cognito = value;
  }
}
