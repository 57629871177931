class ScreenManagingService {
    constructor(shadowDom) {
        this.shadowRoot = shadowDom;
    }

    hideAllScreens() {
      this.hideLoginForm();
    }
    showLoader() {
        this.shadowRoot.querySelector('#loader').style.display = 'flex';
    }
    hideLoader() {
        this.shadowRoot.querySelector('#loader').style.display = 'none';
    }
    showSuccessfulLoginScreen() {
        this.shadowRoot.querySelector('#successful-login').style.display = 'block';
    }
    hideSuccessfulLoginScreen() {
        this.shadowRoot.querySelector('#successful-login').style.display = 'none';
    }
    showEmailForm() {
       this.shadowRoot.querySelector('#login-form').style.display = 'block';
    }
    hideEmailForm() {
        this.shadowRoot.querySelector('#login-form').style.display = 'none';
    }
    showLoginForm() {
        this.shadowRoot.querySelector('#login-form').style.display = 'block';
    }
    hideLoginForm() {
        this.shadowRoot.querySelector('#login-form').style.display = 'none';
    }
    showLoginForm() {
        this.shadowRoot.querySelector('#login-form-federated').style.display = 'none';
        this.shadowRoot.querySelector('#login-form').style.display = 'block';
    }
    hideSelectEmailForm() {
        this.shadowRoot.querySelector('#login-form-federated').style.display = 'none';
    }
    showFederatedForm() {
        this.shadowRoot.querySelector('#login-form-federated').style.display = 'block';
        this.shadowRoot.querySelector('#login-form').style.display = 'none';
    }
}

export default ScreenManagingService;