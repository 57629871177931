class NotificationService {
    constructor(shadowDom) {
        this.shadowRoot = shadowDom;
    }
    showLoader() {
        this.shadowRoot.querySelector('#loader').style.display = 'block';
    }
    hideLoader() {
        this.shadowRoot.querySelector('#loader').style.display = 'none';
    }

    showErrorMessage(errorMessage) {
        this.shadowRoot.querySelector('#error-message').style.display = 'block';
        this.shadowRoot.querySelector('#error-message').innerText = errorMessage;
    }
    showSuccessMessage(successMessage) {
        this.shadowRoot.querySelector('#error-message').style.display = 'block';
        this.shadowRoot.querySelector('#success-message').innerText = successMessage;
    }
    showSuccessfulLoginScreen() {
        this.shadowRoot.querySelector('#successful-login').style.display = 'block';
    }
    hideSuccessfulLoginScreen() {
        this.shadowRoot.querySelector('#successful-login').style.display = 'none';
    }
}

export default NotificationService;