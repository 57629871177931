const forgotPasswordComponentTemplate = `
<style>
h4 {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 14px;
}
#login-form-wrapper {
    font-family: 'Roboto', sans-serif;
    width: 350px;
    margin: 0 auto;
    padding: 0 0 0 0;
    min-height: 526px;
    box-sizing: border-box;
    top: calc(50% - 263px);
    left: calc(50% - 145px);
    position: absolute;
}

.content-wrapper {
   padding: 35px;
   background: #FFFFFF;
   min-height: 300px;
   box-sizing: border-box;
   position: relative;
   padding-bottom: 100px;
}

.login-form * {
    box-sizing: border-box;
}

h2.header {
    font-size: 24px;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    text-align: left;
    padding-top:20px;
    padding-left:10px;
    margin-bottom:0;

}

.login-form input {
    margin-bottom: 15px;
}

.login-form input[type=text], .login-form input[type=email], .login-form input[type=password] {
    display: block;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #BDBDBD;
    background: #EEEEEE;
    height:24px;
    padding:1px 30px 1px 10px;
}

.input-wrapper input:invalid {
    border: 1px solid red;
}

.input-wrapper input:invalid + button {
   background: red;
}

.login-form input:hover, .login-form input:active, .login-form input:focus {
    border: 1px solid #0161fe;
    outline: none;
}

.login-form label, #alternative-login-message {
    color: #777;
    font-size: 0.8em;
}

#loading {
    width: 60px;
    margin: 60px auto;
}

.login-form button[type=submit], #logout-user, #backtologin, #email-submit, #show-login-form {
    display: block;
    margin: 5px auto 0;
    width: 100%;
    height: 40px;
    border: none;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    cursor: pointer;
    background: #0161fe;
    position: relative;
 }
 
 #sign-in-icon {
    position: absolute;
    right: 20px;
    top: 10px;
 }
.loader-container {
    z-index: 5000;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.guideline-loader {
    z-index: 1000;
    display: flex;
    position: relative;
    flex-direction: row;
  }
  
  .guideline-loader path {
    animation: guideline-loader .75s cubic-bezier(1, .5, .5, 1) infinite;
  }
  
  .guideline-loader path:nth-child(1) {
    animation-delay: -0.44s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(2) {
    animation-delay: -0.32s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(3) {
    animation-delay: -0.22s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(4) {
    animation-delay: -0.12s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(5) {
    animation-delay: -0.02s;
    fill: #061856;
  }
  
  .guideline-loader path {
    animation: guideline-loader .75s cubic-bezier(1, .5, .5, 1) infinite;
  }
  
  .guideline-loader path:nth-child(1) {
    animation-delay: -0.44s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(2) {
    animation-delay: -0.32s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(3) {
    animation-delay: -0.22s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(4) {
    animation-delay: -0.12s;
    fill: #0161fe;
  }
  
  .guideline-loader path:nth-child(5) {
    animation-delay: -0.02s;
    fill: #061856;
  }
  
  @keyframes guideline-loader {
    0% {
      opacity: 1;
      box-shadow: none;
      opacity: 1;
      box-shadow: none;
    }
    90%, 100% {
      opacity: .25;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 1);
    }
  }
  
  .gl-disabled-overlay {
    display: flex;
    position: absolute;
    background-color: rgba(var(--surface-card-rgb), 0.2);
    align-items: center;
    flex-direction: column;
    z-index: 3;
    height: 100%;
    width: 100%;
    justify-content: center;
    transition: opacity 0.5s ease;
    opacity: 1;
  }
  #organization-select {
    width: 100%;
  }
  
  #organization-select {
    height:22px;
  }
  
  .gl-disabled-overlay.hide {
    opacity: 0;
    pointer-events: none;
  }

.user-table {
    border-collapse: collapse;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
}

.user-table th,
.user-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.user-table th {
    background-color: #f2f2f2;
}

.user-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.user-table tr:hover {
    background-color: #ddd;
}

#support-link {
    text-align: center;
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 35px 25px 35px;
}

#support-link span {
    color: #0161fe;
}

#loader{
    display: none;
}

#alternative-login-message {    
    text-align: center;
}

.input-wrapper{
    position: relative;
}

.buildIcon {
  width: 16px;
  height: 19px;
  right: 11px !important;
}

#user-icon-reset-password {
  right: 7px;
}

.input-wrapper img {
    position: absolute;
    right: 10px;    
    top: 2px;
}
.input-wrapper .user-icon3 {
  top: 5px;
}
#eye-icon {
    top:3px;
    right: 7px
}
#logoImage, #logoImage2 {
    width: 220px;
    margin: 20px auto;
    display: block;
}
#user-icon {
    padding-top: 2px;
}
#stay-logged-in + label {
    margin-top: -5px;
}
#email-submit:disabled {
    background: gray;
    cursor: auto;
}
.copyright {
    padding: 10px 0;
    text-align: center;
    font-size: 10px;
    color: #757575;
}
</style>
</style>
<form class="login-form" id="verfication-code-form" style="display: block">
    <h4>Enter your email</h4>
    <p id="forgot-password-code-error-message" style="color: red; display: none"></p>
   <div class="input-wrapper">
    <input type="email" id="forgotpasswordemail" name="email" />
    <img class="user-icon3" alt="user-icon"/>
    </div>
    <button id="submit-verfication-code" type="submit"></button>    
</form>
<form class="login-form" id="reset-password-form" style="display: none">
    <h4>Reset password</h4>
    <p id="forgot-password-error-message" style="color: red; display: none"></p>
    <p id="forgot-password-sucess-message" style="color: green; display: none"></p>
   
    <div class="input-wrapper">
    <input type="text" id="forgot-password-username" name="username">
    <img class="user-icon3" id="user-icon-reset-password" alt="user-icon"/>
    </div>

    <div class="input-wrapper">
    <input type="text" id="verification-code" name="code">
    <img class="buildIcon" alt="build-icon"/>     
    </div>

    <div class="input-wrapper">
    <input type="password" id="new-password" name="password"/>
        <img id="forgot-password-eye-icon" class="eye-icon" alt="eye-icon"/>
    </div>

    <div class="input-wrapper">
    <button id="forgotpassowrd-form-submit" type="submit">Submit</button> 
    </div>

</form>
<div>
<p id="alternative-login-message">or</p>
    <button id="backtologin"></button></div>`;

export default forgotPasswordComponentTemplate;