export default class CEGuidelineModal extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.innerHTML = `
            <style>
                .modal {
                    display: none;
                    position: fixed;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    overflow: auto;
                    background-color: rgb(0,0,0);
                    background-color: rgba(0,0,0,0.4);
                }
                .modal-accept {                 
                    color: #FFFDFA;
                    border-radius: var(--border-radius);
                    background-color: #0161fe;
                    border-radius: 4px;
                    border: 1px solid #e0e0e0;
                    gap: 0rem;
                    padding: 6px 8px;
                    align-items: center;
                    display: flex;
                    cursor: pointer;
                }
                .modal-cancel {                 
                    color: #757575;
                    border-radius: var(--border-radius);
                    background-color: transparent;
                    border-radius: 4px;
                    border: 1px solid #e0e0e0;
                    gap: 0rem;
                    padding: 6px 8px;
                    align-items: center;
                    display: flex;
                    margin-right: 5px;
                    cursor: pointer;
                }
                .modal-content {
                    background-color: #fefefe;
                    margin: 15% auto;
                    padding: 20px;
                    border: 1px solid #888;
                    width: 25%;
                    padding: 10px;
                    border-radius: 4px;
                    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.8);
                    border: 0 none;
                }
                .close {
                    color: #aaa;
                    float: right;
                    font-size: 28px;
                    font-weight: bold;
                }
                .close:hover,
                .close:focus {
                    color: black;
                    text-decoration: none;
                    cursor: pointer;
                }
                .button-group {
                    justify-content: right;
                    display: flex;                    
                }
                #modal-message {
                    font-size: 16px;
                    font-weight: 400;
                }
            </style>
            <div class="modal">
                <div class="modal-content">
                    <div>
                        <p id="modal-message"></p>
                    </div>
                    <div class="button-group">
                        <button class="modal-cancel"><span>Cancel</span></button>
                        <button class="modal-accept"><span>Accept</span></button>
                        
                    </div>
                </div>
            </div>
        `;
  }

  connectedCallback() {
    const closeButton = this.shadowRoot.querySelector(".close");
    if (closeButton) {
        closeButton.onclick = () => this.hide();
    }

    const cancelButton = this.shadowRoot.querySelector("#modal-cancel");
    if (cancelButton) {
        cancelButton.onclick = () => this.hide();
    }
  }

  show(message, onAccept, onCancel) {
    this.shadowRoot.querySelector("#modal-message").textContent = message;
    this.shadowRoot.querySelector(".modal").style.display = "block";

    this.shadowRoot.querySelector("button.modal-accept").onclick = () => {
      this.hide();
      if (onAccept) onAccept();
    };

    this.shadowRoot.querySelector("button.modal-cancel").onclick = () => {
      this.hide();
      if (onCancel) onCancel();
    };
  }

  hide() {
    this.shadowRoot.querySelector(".modal").style.display = "none";
  }
}
