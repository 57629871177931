import { environment } from '../environments/environment';
const api = `${environment.api}/api/v1`;
const hasPrevious = `${api}/auth/session/has-previous`;
const invalidatePreviousSessions = `${api}/auth/session/invalidate/previous-sessions`;
const invalidateCurrentSession = `${api}/auth/session/invalidate`;

export default class SessionManagementService {
  constructor() {
    this.authenticated = false;
  }

  async hassPreviousSessions(authToken) {
    try {
      const session = await fetch(hasPrevious,{
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'x-org-id':'',
          'x-org': '',
          'Content-Type': 'application/json'
      }
    });
     return await session.json();
    } catch (e) {
      console.log(e);
    }
  }

  async invalidatePreviousSessions(authToken) {
    try {
      const invalidateSession = await fetch(invalidatePreviousSessions,{
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'x-org-id':'',
          'x-org': '',
          'Content-Type': 'application/json'
        }});
     return await invalidateSession.json();
    } catch (e) {
      console.log(e);
    }
  }

  async invalidateCurrentSession(authToken) {
    try {
      const invalidateSession = await fetch(invalidateCurrentSession,{
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'x-org-id':'',
          'x-org': '',
          'Content-Type': 'application/json'
        }});
     return await invalidateSession.json();
    } catch (e) {
      console.log(e);
    }
  }

}