//import { environment } from 'Configuration';
import { environment } from '../environments/environment'

const currentHost = window.location.origin;

const defaultConfigForCognito = {
    api: environment.api,
    defaultRedirectEnv: environment.defaultRedirectEnv,
    identityPoolId: environment.cognitoIdentityPoolId,
    region: environment.cognitoRegion,
    userPoolId: environment.cognitoUserPoolId,
    userPoolWebClientId: environment.cognitoUserPoolWebClientId,
    loggerName: environment.loggerStreamName,
    loggerRegion:environment.loggerRegion,
    loggerGroupName:environment.loggerGroupName,
    oauth: {
        domain: environment.cognitoOauthDomain,
        redirectSignIn: `${currentHost}/`,
        redirectSignOut: `${currentHost}/`,
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        responseType: 'code'
    }
}

export default defaultConfigForCognito;