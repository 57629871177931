import CognitoService from '../../Services/cognitoService'
import NotificationService from '../notificationService'
import template from './loginComponentTemplate'
import ScreenManagingService from "../screenManagingService";
import UserTokenManagementService from "../../Services/userTokenManagementService";
import {logoEncoded, userIcon, eyeIcon, signInIcon} from "./LoginComponentIcons";
import defaultConfigForCognito from "../../constants/cognitoConfig";
import CEGuidelineCognitoForgotPassword from "../ForgotpasswordComponent/forgotPasswordComponent";
import CEGuidelineCognitoResetPassword from "../ResetPasswordComponent/resetPasswordComponent";
import CEGuidelineModal from "../ModalComponent/modalComponent";
import SessionManagementService from "../../Services/sessionManagementService";
const uiAnalyticsFlag = 'DisableUIAnalytics';
export default class CEGuidelineCognitoLogin extends HTMLElement {
    constructor() {
        super();

        this._apiUrl = null;
        this._cognitoConfig = null;
        this._sessionManagementService = new SessionManagementService();
        this.registerCustomElements();

        this._loginErrorMessage = 'Login failed. Please check your credentials and try again.';
        this.attachShadow({mode: 'open'});
        this.shadowRoot.innerHTML = template;
        this.tokenManagement = new UserTokenManagementService();
        this.notification = new NotificationService(this.shadowRoot);
        this.screen = new ScreenManagingService(this.shadowRoot);
        this.shadowRoot.querySelector('#login-form-submit').addEventListener('click', this.onLogin.bind(this));
        this.shadowRoot.querySelector('#email-submit').addEventListener('click', this.onSubmitEmail.bind(this));
        this.shadowRoot.querySelector('#email').addEventListener('input', this.handleEmailInput.bind(this))
        this.shadowRoot.querySelector('#eye-icon').addEventListener('click', this.onClickShowPassword.bind(this));
        this.shadowRoot.querySelector('#org-submit').addEventListener('click', this.handleSubmit.bind(this));
        this.shadowRoot.querySelector('#sendVerificationCode').addEventListener('click', this.showSendVerificationCodeForm.bind(this));
        this.addIcons();
    }

    registerCustomElements() {
        window.customElements.get('ce-guideline-forgot-password') || window.customElements.define('ce-guideline-forgot-password', CEGuidelineCognitoForgotPassword);
        window.customElements.get('ce-guideline-reset-password') || window.customElements.define('ce-guideline-reset-password', CEGuidelineCognitoResetPassword);
        window.customElements.get('ce-guideline-modal') || window.customElements.define('ce-guideline-modal', CEGuidelineModal);
    }


    get apiUrl() {
        return this._apiUrl;
    }

    set apiUrl(value) {
        this._apiUrl = value;
    }

    get cognitoConfig() {
        return this._cognitoConfig;
    }

    set cognitoConfig(value) {
        if (this._cognitoConfig !== value) {
            this._cognitoConfig = value;
            this._cognito = new CognitoService(this._cognitoConfig);
            this.setCognitoToForgotComponent();
            this.setCognitoToResetComponent();
        }
    }

    get cognito() {
        return this._cognito;
    }

    isValidEmail(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }
    async handleSubmit(e) {
        e.preventDefault();
        const selectedOrg = this.shadowRoot.querySelector('#organization-select').value;
        this.details.selectedOrg = selectedOrg;
        const response = await fetch(`${this.apiUrl}/api/v1/configuration/organization/${selectedOrg}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.details.idToken}`,
                'x-org-id':selectedOrg,
                'x-org': selectedOrg,
                'Content-Type': 'application/json'
            }});
        const data = await response.json();
        if (data?.payload?.settings) {
            const settings = data?.payload?.settings.find(item => item.name === uiAnalyticsFlag)
            if (settings) {
                this.details.uiAnalytics = settings.value?.toLowerCase() === 'false'? 'true': 'false';
            } else {
                this.details.uiAnalytics = true;
            }
        }
        this.dispatchEvent(new CustomEvent("onLogin", {
            detail: {...this.details}
        }));

    }

    handleEmailInput() {
        const submitButton = this.shadowRoot.querySelector('#email-submit');
        const emailInput = this.shadowRoot.querySelector('#email');

        // If the email is valid, enable the submit button, otherwise disable it
        if (this.isValidEmail(emailInput.value)) {
            submitButton.disabled = false;
        } else {
            submitButton.disabled = true;
        }
    }

    showLoginForm() {
        this.shadowRoot.querySelector('#login-form-federated').style.display = 'none';
        this.shadowRoot.querySelector('#login-form-organization-select').style.display = 'none';
        this.shadowRoot.querySelector('#forgotPasswordForm').style.display = 'none';
        this.shadowRoot.querySelector('#resetPasswordForm').style.display = 'none';
        this.shadowRoot.querySelector('#no-organization').style.display = 'none';
        this.shadowRoot.querySelector('#login-form').style.display = 'block';
    }

    showOrganizationSelectForm() {
        this.shadowRoot.querySelector('#login-form-federated').style.display = 'none';
        this.shadowRoot.querySelector('#login-form-organization-select').style.display = 'block';
        this.shadowRoot.querySelector('#login-form').style.display = 'none';
        this.shadowRoot.querySelector('#forgotPasswordForm').style.display = 'none';
        this.shadowRoot.querySelector('#resetPasswordForm').style.display = 'none';
    }

    showSendVerificationCodeForm() {
        this.shadowRoot.querySelector('#login-form-federated').style.display = 'none';
        this.shadowRoot.querySelector('#login-form').style.display = 'none';
        this.shadowRoot.querySelector('#login-form-organization-select').style.display = 'none';
        this.shadowRoot.querySelector('#resetPasswordForm').style.display = 'none';
        this.shadowRoot.querySelector('#forgotPasswordForm').style.display = 'block';
    }

    showResetPasswordForm() {
        this.shadowRoot.querySelector('#login-form-federated').style.display = 'none';
        this.shadowRoot.querySelector('#login-form').style.display = 'none';
        this.shadowRoot.querySelector('#login-form-organization-select').style.display = 'none';
        this.shadowRoot.querySelector('#forgotPasswordForm').style.display = 'none';
        this.shadowRoot.querySelector('#resetPasswordForm').style.display = 'block';
    }

    addIcons() {
        // add icons
        const logoImage = this.shadowRoot.querySelector('#logoImage');
        //  const logoImage2 = this.shadowRoot.querySelector('#logoImage2');
        const userImage = this.shadowRoot.querySelector('#user-icon');
        const userImage2 = this.shadowRoot.querySelector('#user-icon2');
        const eyeImage = this.shadowRoot.querySelector('#eye-icon');
        const signInImage = this.shadowRoot.querySelector('#sign-in-icon');

        userImage.setAttribute('src', userIcon);
        userImage2.setAttribute('src', userIcon);
        logoImage.setAttribute('src', logoEncoded);
        //logoImage2.setAttribute('src', logoEncoded);
        eyeImage.setAttribute('src', eyeIcon);
        signInImage.setAttribute('src', signInIcon);
    }

    async onSubmitEmail(e) {
        e.preventDefault();

        const email = this.shadowRoot.querySelector('#email').value;
        this.shadowRoot.querySelector('#username').value = email;

        const response = await fetch(`${this.apiUrl}/api/v1/auth/login-option`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-org-id': '',
            },
            body: JSON.stringify({
                email: email
            })
        });
        const data = await response.json()
        const domain = data.payload.loginOptionName;

        if (domain !== 'Default') {
            localStorage.setItem('federatedSignIn', true); // needed to show loader when return from federated signin
            return this._cognito.federatedSignIn(domain).then((data)=>{
            }).catch(error => {
                this.showLoginForm()
                // Handle error (e.g., display error message to user)
            });
        } else {
            this.showLoginForm()
        }
    }

    async onGetAvailableOrganizationsList(authToken) {
        this.showOrganizationSelectForm();
        this.screen.showLoader();
        const response = await fetch(`${this.apiUrl}/api/v1/me/organization/list`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`,
                'x-org-id':'',
                'x-org': '',
                'Content-Type': 'application/json'
            }});
        const data = await response.json();
        const organisationList = data.payload;

        if (organisationList.length) {

            this.screen.hideLoader();

            const selectElement = this.shadowRoot.querySelector('#organization-select');

// Clear any existing options
            selectElement.innerHTML = '';

// Iterate over the payload and create options
            organisationList.forEach(org => {
                const option = document.createElement('option');
                option.value = org.orgId;
                option.textContent = org.orgName;
                selectElement.appendChild(option);
            });
        } else {
          const submitButton = this.shadowRoot.querySelector("#org-submit").disabled = true;
          this.shadowRoot.querySelector("#no-organization").style.display = "block";
        }
    }

    async onGetAvailableOrganizationsListSSO(authToken, user) {
        const idToken = user.getSignInUserSession().getIdToken().getJwtToken();
        const accessToken = user.getSignInUserSession().getAccessToken().getJwtToken();
        const refreshToken = user.getSignInUserSession().getRefreshToken().getToken();
        this.details = {
            idToken: idToken,
            accessToken: accessToken,
            refreshToken: refreshToken,
            defaultRedirectEnv: `${defaultConfigForCognito.defaultRedirectEnv}/access-redirect`,
            selectedOrg: null,
            loginError: null
        }

        this.showOrganizationSelectForm();

        const response = await fetch(`${this.apiUrl}/api/v1/me/organization/list`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`,
                'x-org-id':'',
                'x-org': '',
                'Content-Type': 'application/json'
            }});
        const data = await response.json();
        const organisationList = data.payload;
        if (organisationList.length) {
            const selectElement = this.shadowRoot.querySelector('#organization-select');

            selectElement.innerHTML = '';

            organisationList.forEach(org => {
                const option = document.createElement('option');
                option.value = org.orgId;
                option.textContent = org.orgName;
                selectElement.appendChild(option);
            });
        } else {
            this.dispatchEvent(new CustomEvent("onLogin", {
                detail: {...this.details}
            }));
        }
    }
    signInUser(user) {
        const idToken = user.getSignInUserSession().getIdToken().getJwtToken();
        const accessToken = user.getSignInUserSession().getAccessToken().getJwtToken();
        const refreshToken = user.getSignInUserSession().getRefreshToken().getToken();
        // token = user.signInUserSession ? user.signInUserSession.idToken.getJwtToken() : '';

        // DO we need to store token in token manager?
        this.tokenManagement.addUserIDToken(idToken);
        this.details = {
            idToken: idToken,
            accessToken: accessToken,
            refreshToken: refreshToken,
            defaultRedirectEnv: `${defaultConfigForCognito.defaultRedirectEnv}/access-redirect`,
            selectedOrg: null,
            loginError: null
        }

        // this.screen.showSuccessfulLoginScreen();
        //this.notification.showSuccessMessage(this._loginSuccessMessage);

        /* this.dispatchEvent(new CustomEvent("onLogin", {
             detail: {
                 idToken: idToken,
                 accessToken: accessToken,
                 refreshToken: refreshToken,
                 selectedOrg: null,
                 loginError: null
             }
         })); */
        this.onGetAvailableOrganizationsList(idToken);
        this.notification.hideLoader();
    }

    onLogin(e) {
        e.preventDefault();
        this.screen.showLoader();

        const modal = this.shadowRoot.querySelector('#custom-modal');

        const username = this.shadowRoot.querySelector('#username').value?.toLowerCase();
        const password = this.shadowRoot.querySelector('#password').value;
        const rememberMe = this.shadowRoot.querySelector('#stay-logged-in').checked;
        this.screen.hideAllScreens();
        this._cognito.signInUser(username, password, rememberMe).then((user) => {
            if (user && (user.challengeName === 'NEW_PASSWORD_REQUIRED')) {
                this.setUserNameToResetComponent(user);
                this.showResetPasswordForm();
                this.notification.hideLoader();
                return;
            }
            if(user && user?.attributes["custom:qa_automation"] != 1) { // for qa automation users multiple sessions are allowed
                this.checkIfUserHasPreviousSession(user.getSignInUserSession().getIdToken().getJwtToken()).then(() => {
                    if (user && user.getSignInUserSession()) {
                        this.signInUser(user)
                    } else {
                        this.notification.hideLoader();
                    }
                }).catch((data) => {
                    this.logOutUser(data);
                });
            } else {
                if (user && user.getSignInUserSession()) {
                    this.signInUser(user)
                }
            }

        }).catch((e) => {
            const error = String(e);
            if (error.includes('username or password you entered is incorrect') || error.includes('NotAuthorizedException')) {
                this._loginErrorMessage = 'We\'re sorry, the username or password you entered is incorrect.';

            }
            if (error.includes('Max attempts have')) {
                this._loginErrorMessage = 'We\'re sorry, the username or password you entered is incorrect. Max attempts have been reached. Please reach out to your administrator.';
            }
            this.screen.hideLoader();
            this.screen.showLoginForm();
            this.notification.showErrorMessage(this._loginErrorMessage);
            this.notification.hideLoader();
        });
    }

    checkIfUserHasPreviousSession(token) {
        return new Promise((resolve, reject) => {
            this.screen.showLoader();
            this._sessionManagementService.hassPreviousSessions(token).then((data) => {
                this.screen.hideLoader();
                if (data.payload.hasPreviousSessions) {
                    const modal = this.shadowRoot.querySelector('#custom-modal');
                    modal.show(
                        'All active sessions will be closed. Do you want to continue?',
                        () => {
                            this._sessionManagementService.invalidatePreviousSessions(token).then(() => {
                                resolve();
                            })},
                        () => {
                            reject({token: token});
                        }
                    );
                } else {
                    resolve();
                }
            }).catch((error) => {
                this.screen.hideLoader();
                resolve();
            });
        });

    }

    onLoginWithSSO(e) {
        e.preventDefault();
        return this._cognito.signInUserWithSSO();
    }

    invalidateCurrentSession(token) {
        return  this._sessionManagementService.invalidateCurrentSession(token).then(
            () => {
                console.log("Session invalidated");
            },
            (error) => {
                console.log("Error invalidating session", error);
            }
        );
    }

    async logOutUser(data) {
        this.screen.showLoader();
        this.screen.hideAllScreens();
        await this.invalidateCurrentSession(data?.token);
        this._cognito.signOutUser().then(() => {
            this.screen.showLoginForm();
            this.notification.hideLoader();
        })

        this.tokenManagement.removeUserIDToken();
    }

    onClickShowPassword(e) {
        const passwordInput = this.shadowRoot.querySelector("#password"),
            eyeIcon = e.target;
        passwordInput.type = passwordInput.type === "password" ? "text" : "password";
    }

    setCognitoToForgotComponent() {
        const forgotPasswordComponent = this.shadowRoot.querySelector('ce-guideline-forgot-password');
        if (forgotPasswordComponent) {
            forgotPasswordComponent.cognito = this._cognito;
        }
    }

    setCognitoToResetComponent() {
        const resetPasswordComponent = this.shadowRoot.querySelector('ce-guideline-reset-password');
        if (resetPasswordComponent) {
            resetPasswordComponent.cognito = this._cognito;
        }
    }

    setUserNameToResetComponent(user) {
        const resetPasswordComponent = this.shadowRoot.querySelector('ce-guideline-reset-password');
        if (resetPasswordComponent) {
            resetPasswordComponent.user = user;
        }
    }
}

// window.customElements.define('ce-guideline-cognito-login', CEGuidelineCognitoLogin);