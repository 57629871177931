import { Amplify, Logger, AWSCloudWatchProvider } from 'aws-amplify';

const logger = new Logger('stargate.ui', 'INFO');
import defaultConfigForCognito from "../constants/cognitoConfig";


Amplify.register(logger);

logger.addPluggable(new AWSCloudWatchProvider({
    logGroupName: defaultConfigForCognito.loggerGroupName,
    logStreamName: `${defaultConfigForCognito.loggerName}-${new Date().toLocaleDateString('en-US')}`,
    region: defaultConfigForCognito.loggerRegion,
}));

export default logger;